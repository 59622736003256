import React, { FC, useRef, useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import IconCustom from 'common/IconCustom';
import useClickOutside from 'hooks/useClickOutside';
import { IPropsDesktopSubNav } from './model';

import './DesktopNavItem.scss';
import './ShadowDesktopNavItem.scss';

const DesktopNavItem: FC<IPropsDesktopSubNav> = ({
  properties: { navigationWithSubMenu, label, url, ariaLabel },
}) => {
  const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);
  const [activeButtonLink, setActiveButtonLink] = useState<boolean>(false);
  const navItemRef = useRef(null);

  const handleNavigationItemClick = useCallback(
    (id: string | null) => () => {
      setOpenSubMenu(id === openSubMenu ? null : id);
    },
    [openSubMenu]
  );

  useEffect(() => {
    const currentURL = window.location.href;

    navigationWithSubMenu?.forEach(({ properties }) => {
      if (currentURL.toLowerCase().includes(properties.url[0].url.toLowerCase())) {
        return setActiveButtonLink(true);
      }
    });
  }, []);

  useClickOutside(navItemRef, handleNavigationItemClick(null));

  return (
    <li
      data-test="desktopNavItem"
      ref={navigationWithSubMenu ? navItemRef : null}
      className="desktop-nav__li"
      onMouseEnter={handleNavigationItemClick(label)}
      onMouseLeave={handleNavigationItemClick(null)}
    >
      {navigationWithSubMenu && navigationWithSubMenu?.length >= 1 ? (
        <>
          <button
            className={classNames('desktop-nav__link desktop-nav__link--with-corner', {
              'desktop-nav__link--active': activeButtonLink,
            })}
            type="button"
            aria-label={ariaLabel}
          >
            <span>{label}</span>
            <IconCustom
              icon="arrow"
              className={classNames('desktop-nav__icon', {
                [`desktop-nav__icon--close`]: openSubMenu && openSubMenu === label,
              })}
            />
          </button>
          <ul
            className={classNames('desktop-nav__sub-menu', {
              [`desktop-nav__sub-menu--open`]: openSubMenu && openSubMenu === label,
            })}
          >
            {navigationWithSubMenu.map(({ properties: { label: subLabel, url: [{url, queryString}] } }) => (
              <li key={subLabel} className="sub-menu__li">
                <Link
                  activeClassName="sub-menu__link--active"
                  className="sub-menu__link"
                  to={queryString && queryString !== '' ? `${url}${queryString}` : url}
                >
                  {subLabel}
                </Link>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <Link
          activeClassName="desktop-nav__link--active"
          className="desktop-nav__link"
          partiallyActive
          to={url?.[0].url}
        >
          {label}
        </Link>
      )}
    </li>
  );
};

export default DesktopNavItem;
